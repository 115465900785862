<template>
  <div class="container">
    <!-- Хедер с названием и изображением -->
    <header class="header">
      <h1 class="header-title">STL Viewer</h1>
    </header>

    <!-- Контейнер для канваса Three.js -->
    <div class="three-canvas" ref="threeCanvas"></div>

    <div class="menu-container">
      <div class="left">
        <!-- Поле для выбора STL файла -->
        <label for="file-input" class="file-label">Выберите файл</label>
        <input type="file" id="file-input" @change="onFileChange" class="file-input"/>

        <!-- Контейнер для кнопки и палитры цвета -->
        <!-- Контейнер для кнопки и палитры цвета -->
        <div class="color-container">
          <button @click="showColorPicker = !showColorPicker" class="color-button">Цвет</button>

          <!-- Палитра для выбора цвета -->
          <div v-if="showColorPicker" class="color-picker">
            <input type="color" v-model="color" @input="updateColor"/>
          </div>
        </div>


        <!-- Слайдер для изменения масштаба модели -->
        <div class="scale-slider">
          <label for="scale">Масштаб: {{ scaleFactor.toFixed(1) }}</label>
          <input type="range" id="scale" min="0.1" max="10" step="0.1" v-model.number="scaleFactor"/>
        </div>

        <div class="fill-density-slider">
          <label for="fillDensity">Плотность заполнения: {{ fillDensity.toFixed(0) }}%</label>
          <input type="range" id="fillDensity" min="1" max="100" step="1" v-model.number="fillDensity"/>
        </div>

        <!--    <div>-->
        <!--      <button @click="toggleSupports">{{ showSupports ? 'Убрать поддержки' : 'Генерировать поддержки' }}</button>-->
        <!--    </div>-->

        <!-- Выпадающий список для выбора материала -->
        <div class="material-selector">
          <label for="material">Материал:</label>
          <select id="material" v-model="selectedMaterial" @change="updateMass">
            <option v-for="(density, material) in materialDensities" :key="material" :value="material">
              {{ material }}
            </option>
          </select>
        </div>
      </div>
      <div class="right">
        <!-- Отображение размеров модели -->
        <div class="model-dimensions" v-if="dimensions">
          <p>Длина: {{ dimensions.length.toFixed(2) }} мм</p>
          <p>Ширина: {{ dimensions.width.toFixed(2) }} мм</p>
          <p>Высота: {{ dimensions.height.toFixed(2) }} мм</p>
          <p>Масса: {{ dimensions.mass.toFixed(2) }} гр</p>
          <p>Цена: {{ dimensions.price.toFixed(2) }} р</p>
          <p>Цена: {{ dimensions.volume.toFixed(2) }} р</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Импорт необходимых модулей
import * as THREE from 'three';
import {STLLoader} from 'three/examples/jsm/loaders/STLLoader';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {MeshBVH, disposeBoundsTree} from 'three-mesh-bvh';
import {CSG} from 'three-csg-ts';

export default {
  name: 'ThreeJSComponent',
  data() {
    return {
      currentMesh: null,
      dimensions: null,
      scaleFactor: 1,
      fillDensity: 20,
      showColorPicker: false,
      color: '#555555', // Цвет модели по умолчанию
      selectedMaterial: 'ABS', // Начальный выбранный материал
      materialDensities: {},
      materialPrices: {},
      showSupports: false, // Состояние отображения поддержек
    };
  },
  mounted() {
    this.initThreeJS();
    this.fetchMaterialPrices();

    window.addEventListener('click', (event) => {
      this.raycast({x: event.clientX, y: event.clientY});
    });
  },

  beforeUnmount() {
    // Удалите BVH при уничтожении компонента
    if (this.currentMesh) {
      disposeBoundsTree(this.currentMesh.geometry);
    }
    window.removeEventListener('resize', this.onWindowResize); // Не забудьте удалить обработчик события
  },

  methods: {
    async fetchMaterialPrices() {
      try {
        // const response = await fetch('https://teststl.apptestbot.ru/api/v1/stlmodels/', {
        //   // credentials: 'include' // Включает куки в запрос
        //   headers: {
        //     'Authorization': `Bearer ${token}`,  // Здесь передается JWT токен
        //   }
        // });

        const response = await fetch('https://teststl.apptestbot.ru/api/material-prices/');

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        this.materialDensities = data.reduce((acc, item) => {
          acc[item.name] = item.mass; // Замените на массу материала
          return acc;
        }, {});
        this.materialPrices = data.reduce((acc, item) => {
          acc[item.name] = item.price_per_gram; // Замените на цену материала
          return acc;
        }, {});
      } catch (error) {
        console.error('Ошибка при получении данных о материалах:', error);
      }
    },

    raycast(mouse) {
      const raycaster = new THREE.Raycaster();
      const pointer = new THREE.Vector2(
          (mouse.x / window.innerWidth) * 2 - 1,
          -(mouse.y / window.innerHeight) * 2 + 1
      );

      raycaster.setFromCamera(pointer, this.camera);
      const intersects = raycaster.intersectObject(this.currentMesh);

      if (intersects.length > 0) {
        console.log('Пересечение:', intersects[0]);
      }
    },

    initThreeJS() {
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(75, this.$refs.threeCanvas.clientWidth / this.$refs.threeCanvas.clientHeight, 0.1, 10000);
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(this.$refs.threeCanvas.clientWidth, this.$refs.threeCanvas.clientHeight);
      this.renderer.setClearColor(0x1B262C);
      this.$refs.threeCanvas.appendChild(this.renderer.domElement);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.25;
      this.controls.enableZoom = true; // Убедитесь, что масштабирование включено
      this.controls.zoomSpeed = 1.0; // Настройка скорости масштабирования

      // Добавление источника света в сцену
      const light1 = new THREE.DirectionalLight(0xffffff, 2);
      light1.position.set(10, 10, 10).normalize();
      this.scene.add(light1);

      const light2 = new THREE.DirectionalLight(0xffffff, 2);
      light2.position.set(-10, 10, 10).normalize();
      this.scene.add(light2);

      const light3 = new THREE.DirectionalLight(0xffffff, 2);
      light3.position.set(10, -10, 10).normalize();
      this.scene.add(light3);

      const light4 = new THREE.DirectionalLight(0xffffff, 2);
      light4.position.set(-10, -10, 10).normalize();
      this.scene.add(light4);

      const light5 = new THREE.DirectionalLight(0xffffff, 2);
      light5.position.set(10, 10, -10).normalize();
      this.scene.add(light5);

      const light6 = new THREE.DirectionalLight(0xffffff, 2);
      light6.position.set(-10, 10, -10).normalize();
      this.scene.add(light6);

      const light7 = new THREE.DirectionalLight(0xffffff, 2);
      light7.position.set(10, -10, -10).normalize();
      this.scene.add(light7);

      const light8 = new THREE.DirectionalLight(0xffffff, 2);
      light8.position.set(-10, -10, -10).normalize();
      this.scene.add(light8);

      const ambientLight = new THREE.AmbientLight(0x404040); // Мягкий белый свет
      this.scene.add(ambientLight);

      window.addEventListener('resize', this.onWindowResize);

      this.animate();
    },

    onWindowResize() {
      // Обновляем размеры камеры и рендера при изменении размеров окна
      const canvasWidth = this.$refs.threeCanvas.clientWidth;
      const canvasHeight = this.$refs.threeCanvas.clientHeight;
      this.camera.aspect = canvasWidth / canvasHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(canvasWidth, canvasHeight);

      // Пересоздаем контроллеры управления
      this.controls.dispose(); // Удаляем старые контроллеры
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.25;
      this.controls.enableZoom = true; // Убедитесь, что масштабирование включено
      this.controls.zoomSpeed = 1.0; // Настройка скорости масштабирования

      if (this.currentMesh) {
        this.calculateDimensions(this.currentMesh);//this.centerModel(this.currentMesh);
      }
      this.controls.update();
    },

    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },

    loadSTLModel(file) {
      const loader = new STLLoader();
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        try {
          const geometry = loader.parse(arrayBuffer);
          const material = new THREE.MeshStandardMaterial({
            color: new THREE.Color(this.color),
            metalness: 0.1, // Сделать поверхность блестящей
            roughness: 0.75, // Сделать поверхность гладкой
          });
          const mesh = new THREE.Mesh(geometry, material);

          this.clearScene();

          // Создаем BVH для модели
          const bvhGeometry = new MeshBVH(geometry);
          mesh.geometry.dispose(); // Освобождаем старую геометрию
          mesh.geometry = bvhGeometry;

          this.scene.add(mesh);
          this.currentMesh = mesh;

          this.calculateDimensions(mesh);
          this.centerModel(mesh);
        } catch (error) {
          console.error('Ошибка при загрузке модели:', error);
        }
      };

      reader.readAsArrayBuffer(file);
    },

    clearScene() {
      const objectsToRemove = this.scene.children.filter((obj) => obj.type === 'Mesh');
      objectsToRemove.forEach((obj) => {
        obj.geometry.dispose();
        obj.material.dispose();
        this.scene.remove(obj);
      });
    },

    updateColor(event) {
      this.color = event.target.value;
      if (this.currentMesh) {
        this.currentMesh.material.color.set(this.color);
      }
    },

    calculateDimensions(mesh) {
      const box = new THREE.Box3().setFromObject(mesh);
      const size = box.getSize(new THREE.Vector3());

      this.dimensions = {
        length: size.x * this.scaleFactor,
        width: size.z * this.scaleFactor,
        height: size.y * this.scaleFactor,
      };

      this.updateMass();
    },

    // updateMass() {
    //   if (this.currentMesh) {
    //     const volume = this.dimensions.length * this.dimensions.width * this.dimensions.height
    //     const density = this.materialDensities[this.selectedMaterial] || 1; // Плотность материала
    //     const pricePerGram = this.materialPrices[this.selectedMaterial] || 0;
    //     this.dimensions.mass = volume * density * (this.fillDensity / 100); // Масса в граммах (если объем в куб. мм) fillDensity - плотность заполнения
    //     this.dimensions.price = this.dimensions.mass * pricePerGram; // Стоимость модели
    //   }
    // },

    computeMeshVolume(geometry) {
      let volume = 0;
      const position = geometry.attributes.position; // Получаем позиции вершин
      const facesCount = position.count / 3; // Количество треугольников

      // Перебираем все треугольники
      for (let i = 0; i < facesCount; i++) {
        const vA = new THREE.Vector3(
            position.getX(i * 3),
            position.getY(i * 3),
            position.getZ(i * 3)
        );
        const vB = new THREE.Vector3(
            position.getX(i * 3 + 1),
            position.getY(i * 3 + 1),
            position.getZ(i * 3 + 1)
        );
        const vC = new THREE.Vector3(
            position.getX(i * 3 + 2),
            position.getY(i * 3 + 2),
            position.getZ(i * 3 + 2)
        );

        // Вычисляем объем тетраэдра, образованного треугольником и началом координат
        volume += this.computeTetrahedronVolume(vA, vB, vC);
      }

      return Math.abs(volume); // Возвращаем модуль объема
    },

    // Вспомогательный метод для вычисления объема тетраэдра
    computeTetrahedronVolume(vA, vB, vC) {
      return vA.dot(vB.cross(vC)) / 6;
    },

    // updateMass() {
    //   if (this.currentMesh) {
    //     // Получаем геометрию текущей модели
    //     const geometry = this.currentMesh.geometry;
    //
    //     // Вычисляем точный объем модели по треугольной сетке
    //     const volume = this.computeMeshVolume(geometry);
    //
    //     // Учитываем масштабирование (volume * scaleFactor^3)
    //     const scaledVolume = volume * Math.pow(this.scaleFactor, 3);
    //
    //     // Учитываем заполнение модели
    //     const effectiveVolume = scaledVolume * (this.fillDensity / 100); // Учитываем коэффициент заполнения
    //
    //     // Получаем плотность материала
    //     const density = this.materialDensities[this.selectedMaterial] || 1; // Плотность материала
    //
    //     // Рассчитываем массу на основе объема и плотности
    //     this.dimensions.mass = effectiveVolume * density; // Масса в граммах
    //
    //     // Цена за грамм материала
    //     const pricePerGram = this.materialPrices[this.selectedMaterial] || 0;
    //     this.dimensions.price = this.dimensions.mass * pricePerGram; // Стоимость модели
    //   }
    // },

    updateMass() {
      if (this.currentMesh) {
        // Получаем геометрию текущей модели
        const geometry = this.currentMesh.geometry;

        // Вычисляем точный объем модели по треугольной сетке
        this.dimensions.volume = this.computeMeshVolume(geometry);

        // Получаем объем полой модели с учетом стенок
        const hollowVolume = this.computeHollowVolume();

        // Учитываем масштабирование
        const scaledVolume = hollowVolume * Math.pow(this.scaleFactor, 3);

        // Учитываем заполнение модели
        const effectiveVolume = scaledVolume * (this.fillDensity / 100); // Учитываем коэффициент заполнения

        // Получаем плотность материала
        const density = this.materialDensities[this.selectedMaterial] || 1; // Плотность материала

        // Рассчитываем массу на основе объема и плотности
        this.dimensions.mass = effectiveVolume * density; // Масса в граммах

        // Цена за грамм материала
        const pricePerGram = this.materialPrices[this.selectedMaterial] || 0;
        this.dimensions.price = this.dimensions.mass * pricePerGram; // Стоимость модели
      }
    },


    computeHollowVolume() {
      // Получаем внутреннюю геометрию
      const hollowGeometry = this.getHollowGeometry();
      return this.computeMeshVolume(hollowGeometry);
    },

    getHollowGeometry() {
      const wallThickness = 1; // Укажите толщину стенки
      const boundingBox = new THREE.Box3().setFromObject(this.currentMesh);

      // Создайте внешний и внутренний меши
      const outerMesh = new THREE.Mesh(this.currentMesh.geometry, new THREE.MeshStandardMaterial());

      // Клонируем геометрию и уменьшаем ее размер
      const innerGeometry = this.currentMesh.geometry.clone();

      // Уменьшаем размеры в зависимости от толщины стенки
      const scaleFactors = new THREE.Vector3(
          1 - (2 * wallThickness / boundingBox.getSize(new THREE.Vector3()).x),
          1 - (2 * wallThickness / boundingBox.getSize(new THREE.Vector3()).y),
          1 - (2 * wallThickness / boundingBox.getSize(new THREE.Vector3()).z)
      );

      innerGeometry.scale(scaleFactors.x, scaleFactors.y, scaleFactors.z);
      const innerMesh = new THREE.Mesh(innerGeometry, new THREE.MeshStandardMaterial());

      // Выполните булеву операцию вычитания
      const hollowMesh = CSG.subtract(outerMesh, innerMesh);

      return hollowMesh.geometry;
    },

    centerModel(mesh) {
      // Получаем геометрию модели и вычисляем её границы
      const geometry = mesh.geometry;
      geometry.computeBoundingBox();
      const boundingBox = geometry.boundingBox;

      if (!boundingBox) return;

      // Получаем центр BoundingBox
      const center = new THREE.Vector3();
      boundingBox.getCenter(center);

      // Преобразуем координаты центра в мировую систему
      mesh.localToWorld(center);

      // Перемещаем модель так, чтобы её центр совпадал с началом координат
      mesh.position.sub(center);

      const initialScale = 1;
      mesh.scale.set(initialScale, initialScale, initialScale);

      const size = boundingBox.getSize(new THREE.Vector3());
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = this.camera.fov * (Math.PI / 180);
      const cameraDistance = (maxDim / 2) / Math.tan(fov / 2);

      this.camera.position.set(0, 0, cameraDistance);
      this.camera.lookAt(0, 0, 0);

      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },

    updateScale() {
      if (this.currentMesh) {
        this.calculateDimensions(this.currentMesh);
        this.renderer.render(this.scene, this.camera);
      }
    },

    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.loadSTLModel(file);
      }
    },
  },

  watch: {
    scaleFactor: 'updateScale',
    selectedMaterial: 'updateMass',
    fillDensity: 'updateMass',
  },
};
</script>

<style scoped>


.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left .right {
  display: flex;
  align-items: center;
}

.left {
  justify-content: flex-start;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Изменено на flex-start для выравнивания элементов по левому краю */
  margin-left: 10px;
}

/* Основной контейнер */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  background-color: #2D3A41; /* Цвет фона всего приложения */
}

/* Хедер */
.header {
  background-color: #2D3A41;
  color: #fff;
  width: 100%;
  padding: 5px;
  text-align: center;
  position: relative;
}

.header-title {
  margin: 5px 0;
}

/* Контейнер для канваса Three.js */
.three-canvas {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  background-color: #1B262C; /* Цвет фона канваса */
}

/* Поле для выбора файла */
.file-input {
  margin-bottom: 20px;
}

.color-buttons button {
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  background-color: #D4477B; /* Цвет кнопок */
}


.color-buttons button:hover {
  opacity: 0.8;
}

/* Отображение размеров модели */
.model-dimensions {
  text-align: left;
  margin-bottom: 10px;
}

/* Слайдер масштаба */
.scale-slider {
  text-align: center;
  margin-top: 10px;
}

.scale-slider label {
  display: block;
  margin-bottom: 5px;
}

.scale-slider input {
  width: 100%;
}

/* слайдер плотности заполнения */
.fill-density-slider {
  text-align: center; /* Центрирование текста */
  margin-top: 10px; /* Отступ сверху */
}

.fill-density-slider label {
  display: block; /* Блоковый элемент */
  margin-bottom: 5px; /* Отступ снизу */
  font-size: 16px; /* Размер шрифта */
  color: #ffffff; /* Цвет текста */
}

.fill-density-slider input {
  width: 100%; /* Ширина на всю ширину родителя */
  background-color: #1B262C; /* Цвет фона для слайдера */
  border: 1px solid #D4477B; /* Цвет границы */
  border-radius: 5px; /* Закругленные углы */
  cursor: pointer; /* Курсор в виде указателя */
}


/* Поле для выбора файла */
.file-label {
  display: block;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  background-color: #D4477B; /* Цвет кнопок */
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.file-input {
  display: none; /* Скрыть стандартное поле выбора файла */
}

/* Контейнер для кнопки и палитры цвета */
.color-container {
  display: flex;
  align-items: center; /* Выравнивание по вертикали */
  justify-content: center; /* Центрирование содержимого */
  width: 100%; /* Убедитесь, что контейнер занимает всю ширину */
}

/* Кнопка */
.color-button {
  flex: 1; /* Занять половину доступного пространства */
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  background-color: #D4477B; /* Цвет кнопок */
}

/* Палитра выбора цвета */
.color-picker {
  display: flex;
  justify-content: center; /* Центрирование содержимого по горизонтали */
  align-items: center; /* Выравнивание по вертикали */
  background-color: #2D3A41; /* Цвет фона палитры */
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px; /* Отступ слева от кнопки */
  flex: 1; /* Занять половину доступного пространства */
}

.color-picker input[type="color"] {
  border: none; /* Убираем границу */
  background: transparent; /* Делаем фон прозрачным */
  cursor: pointer; /* Указываем курсор в виде указателя */
  width: 75%; /* Установите ширину по желанию */
  padding: 0; /* Убираем отступы */
}

/* Контейнер для выпадающего списка */
.material-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #ffffff; /* Цвет текста */
}

/* Лейбл для выпадающего списка */
.material-selector label {
  margin-right: 10px;
  font-weight: bold;
}

/* Выпадающий список */
.material-selector select {
  padding: 10px;
  border: 1px solid #D4477B; /* Цвет границы */
  border-radius: 5px;
  background-color: #2D3A41; /* Цвет фона списка */
  color: #ffffff; /* Цвет текста внутри списка */
  font-size: 16px;
  cursor: pointer;
}

/* Стили для элементов выпадающего списка */
.material-selector option {
  background-color: #1B262C; /* Цвет фона элементов списка */
  color: #ffffff; /* Цвет текста элементов списка */
}

</style>

