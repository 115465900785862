<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff; /* Цвет текста для контраста на темном фоне */
  margin: 0; /* Убираем отступ сверху */
  padding: 0;
  background-color: #1B262C; /* Цвет фона для всего приложения */
  height: 100vh; /* Устанавливаем высоту на весь экран */
  overflow: auto; /* Убираем скроллбар, если он не нужен */
}
</style>

<template>
  <div id="app">
    <ThreeJSComponent/>
  </div>
</template>


<script>
import ThreeJSComponent from './components/ThreeJSComponent.vue';

export default {
  components: {
    ThreeJSComponent
  }
};
</script>